
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
main {
  display: block;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
button {
  overflow: visible;
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
input {
  overflow: visible;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
select {
  text-transform: none;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
#app[v-cloak] {
  opacity: 0;
}
#app {
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  opacity: 1;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  background-color: #2D2C2D;
  overflow: hidden;
}
body, h3, h4, h5, h6{
  font-family: 'Roboto', sans-serif;
}
h1, h2{
  font-family: 'Poppins', sans-serif;
}
#app {
  width: 100vw;
  height: 100vh;
  padding-top: 48px;
  overflow: hidden;
}
.mobile#app {
  min-height: 100vh;
  height: auto;
  overflow: hidden;
}
#page-container {
  position: absolute;
  top: calc(50% + 24px);
  left: 50%;
  height: calc(100vh - 80px);
  width: 100vw;
  /* transition: transform 0.3s ease-in-out; */
}

/* Safari Fix */

/* .mobile #page-container {
  position: absolute;
  top: calc(50% + 24px);
  left: 0;
  height: auto;
  width: auto;
}  

.mobile.doublePage #page-container {
  left: 50%;
} */
#page-container img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
@-webkit-keyframes blink {
0% {
    opacity: 0.0;
}
33% {
    opacity: 0.0;
}
66% {
    opacity: 1.0;
}
100% {
    opacity: 0.0;
}
}
@keyframes blink {
0% {
    opacity: 0.0;
}
33% {
    opacity: 0.0;
}
66% {
    opacity: 1.0;
}
100% {
    opacity: 0.0;
}
}
.contentLink,
.contentLinkThree{
  position: absolute;
  cursor: pointer;
  /* transition: opacity 0.2s ease; */
  background-color: #ffffff;
}
.contentLinkNone {
  position: absolute;
  cursor: pointer;
}
.currentPage .contentLink,
.leftPage .contentLink,
.rightPage .contentLink {
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  border-radius: 8px;
  opacity: 0.0;
}
.currentPage .contentLinkThree,
.leftPage .contentLinkThree,
.rightPage .contentLinkThree {
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 3;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 3;
  border-radius: 8px;
  opacity: 0.0;
}
.currentPage .contentLink:hover,
.leftPage .contentLink:hover,
.rightPage .contentLink:hover,
.currentPage .contentLinkThree:hover,
.leftPage .contentLinkThree:hover,
.rightPage .contentLinkThree:hover
.currentPage .contentLinkNone:hover,
.leftPage .contentLinkNone:hover,
.rightPage .contentLinkNone:hover
 {
  opacity: 0;
}
.contentLink a,
.contentLinkThree a {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.contentOverlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.page-leave-active,
.page-leave-to {
  opacity: 0!important;
}


.page {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  user-drag: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  -webkit-transition: all 0.6s cubic-bezier(.55,0,.1,1);
  transition: all 0.6s cubic-bezier(.55,0,.1,1);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}
.currentPage{
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 1;
}

/* Safari Fix */

/* .mobile .currentPage{
  transform: translate(0, -50%);
} */
.mobile .nextPage,
.mobile .previousPage,
.mobile .previousPreviousPage,
.mobile .nextNextPage {
  opacity: 0;
  pointer-events: none;
}
.page img {
  position: absolute;
  user-drag: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.previousPage{
  -webkit-transform: translate(calc(-150% - 20px) , -50%);
          transform: translate(calc(-150% - 20px) , -50%);
  opacity: 0;
}
.previousPreviousPage{
  -webkit-transform: translate(calc(-250% - 40px) , -50%);
          transform: translate(calc(-250% - 40px) , -50%);
  opacity: 0;
}
.nextPage{
  -webkit-transform: translate(calc(50% + 20px) , -50%);
          transform: translate(calc(50% + 20px) , -50%);
  opacity: 0;
}
.nextNextPage{
  -webkit-transform: translate(calc(150% + 40px) , -50%);
          transform: translate(calc(150% + 40px) , -50%);
  opacity: 0;
}
.previousPreviousSection{
  -webkit-transform: translate(-50%, -250%);
          transform: translate(-50%, -250%);
  opacity: 0;
}
.previousSection{
  -webkit-transform: translate(-50%, -150%);
          transform: translate(-50%, -150%);
  opacity: 0;
}
.nextSection{
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  opacity: 0;
}
.nextNextSection{
  -webkit-transform: translate(-50%, 150%);
          transform: translate(-50%, 150%);
  opacity: 0;
}
.previousLeftPage {
  -webkit-transform: translate(-300%, -50%);
          transform: translate(-300%, -50%);
  opacity: 0;
}
.previousRightPage {
  opacity: 0;
  -webkit-transform: translate(-200%, -50%);
          transform: translate(-200%, -50%);
}
.nextLeftPage {
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  opacity: 0;
}
.nextRightPage {
  opacity: 0;
  -webkit-transform: translate(200%, -50%);
          transform: translate(200%, -50%);
}
.leftPage {
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
  opacity: 1;
}
.rightPage {
  opacity: 1;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.right-to-left .previousPage{
  -webkit-transform: translate(calc(50% + 20px) , -50%);
          transform: translate(calc(50% + 20px) , -50%);
}
.right-to-left .previousPreviousPage{
  -webkit-transform: translate(calc(150% + 40px) , -50%);
          transform: translate(calc(150% + 40px) , -50%);
}
.right-to-left .nextPage{
  -webkit-transform: translate(calc(-150% - 20px) , -50%);
          transform: translate(calc(-150% - 20px) , -50%);
}
.right-to-left .nextNextPage{
  -webkit-transform: translate(calc(-250% - 40px) , -50%);
          transform: translate(calc(-250% - 40px) , -50%);
}


.nav-bar__link i {
  display: block;
}
.r90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.r180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.r270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}





































.mobile-page[data-v-13cf244c] {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 10px 10px 120px;
  position: relative;
  top: 45px;
  min-height: calc(100vh - 45px);
}



.nav-bar[data-v-b2d63fc6] {
  position: fixed;
  width: 100vw;
  background-color: #f11e22;
  color: #fff;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  cursor: pointer;
  height: 45px;
  padding: 10px;
  z-index: 1;
}
.nav-title[data-v-b2d63fc6] {
  line-height: 25px;
  display: inline-block;
}
.nav-menu-button[data-v-b2d63fc6] {
  float: right;
}
#mobile-nav-bar__logo[data-v-b2d63fc6] {
  max-height: 100%;
}

#mobile-menu[data-v-6d48a67d] {
  position: fixed;
  background: #f11e22;
  width: 100%;
  text-align: right;
  top: 45px;
  height: calc(100vh - 45px);
  overflow-y: scroll;
  z-index: 5;
}
#mobile-menu ul[data-v-6d48a67d] {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li a[data-v-6d48a67d] {
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}


.nav-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  text-align: left;
  height: 48px;
  line-height: 48px;
  background-color: rgba(91, 71, 88, 1);
  color: white;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.nav-bar__container {
  height: 100%;
  max-width: 100vw;
  margin: auto;
}
.nav-bar__logo {
  display: inline-block;
  vertical-align: middle;
  height: 60%;
  margin-right: 10px;
  margin-left: 10px;
}
.nav-bar__logo-image {
  max-height: 100%;
  max-width: calc(100vw - 250px);
}
.nav-bar__divider svg {
  position: relative;
  top: 6px;
  pointer-events: inherit;
}
.nav-bar__link svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: inherit;
}
.nav-bar__links ul {
  margin: 0;
  padding: 0;
  height: 100%;
}
.nav-bar__links {
  float: right;
  cursor: pointer;
  height: 100%;
}
.nav-bar__links li a {
  color: #fff;
  text-decoration: none;
}
.nav-bar__link {
  float: left;
  border-color: rgba(91, 71, 88, 1);
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  height: 100%;
  width: 48px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
}
.nav-bar__text {
  font-size: 0.9em;
  letter-spacing: 0.01em;
  font-weight: 700;
}
.nav-bar__reader {
  position: absolute;
  top:0;
  left:0;
  right:0;
  margin: auto;
  width:200px;
  cursor: pointer;
  text-align: center;
  font-weight:700
}
.nav-bar__link a {
  color: white;
}
.nav-bar__link:first-child {
  border-left-width: 2px;
}
.nav-bar__link:last-child {
  border-right-width: 2px;
}
.nav-bar__link--active {
  background-color: #006731;
  border-color: white;
}
.nav-bar__link,
.nav-bar__text {
  display: inline-block;
}
.right-to-left .nav-bar {
  direction: rtl;
  text-align: right;
}
.right-to-left .nav-bar__links {
  float: left;
}
.right-to-left .nav-bar__title,
.right-to-left .nav-bar__link {
  float: right;
}
.right-to-left .nav-bar__link:first-child {
  border-right-width: 2px;
  border-left-width: 1px;
}
.right-to-left .nav-bar__link:last-child {
  border-left-width: 2px;
  border-right-width: 1px;
}
@media (max-width: 950px) {
.nav-bar__divider,
  .nav-bar__page {
    display: none;
}
}
@media (max-width: 600px) {
.nav-bar__text {
    display: none;
}
}
@media (min-width: 600px) and (min-height: 600px) {
.nav-bar__container {
    padding-left: 10px;
    padding-right: 10px;
}
}



























/*TODO make it position correctly */
/*TODO make it look nicer? */
.languages-fade-enter-active, .languages-fade-leave-active {
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}
.languages-fade-enter, .languages-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
.languages-panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
  background-color: #2D2C2D;
  -webkit-box-shadow: -5px 15px 10px 10px rgba(0,0,0,0.3);
          box-shadow: -5px 15px 10px 10px rgba(0,0,0,0.3);
}
.languages-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.languages-item {
  position: relative;
  height: 48px;
  border-style: solid;
  border-color: #fff;
  color: white;
  text-align: center;
  user-drag: none;  
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.languages-item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  line-height: 48px;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 1px;
}
.languages-item:hover {
  background-color: rgba(91, 71, 88, 1);
}
.languages-item:first-child {
  border-top-width: 2px;
}
.languages-item:last-child {
  border-bottom-width: 2px;
}

.navigation-link {
  opacity: 0.5;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.navigation-link:hover {
  opacity: 1;
}
.navigation-link a {
  color: #111;
}
.navigation-link svg {
  fill: #fff;
}
.navigation-link--next {
  position: fixed;
  right: 10px;
  top: 50%;
}
.navigation-link--prev {
  position: fixed;
  left: 10px;
  top: 50%;
}
.navigation-link--up {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 45px;
}
.navigation-link--down {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
}
.right-to-left .navigation-link--next {
  left: 0;
  right: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.right-to-left .navigation-link--prev {
  right: 0;
  left: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}


.navigation-link {
  opacity: 0.5;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.navigation-link:hover {
  opacity: 1;
}
.navigation-link a {
  color: #111;
}
.navigation-link svg {
  fill: #fff;
}
.navigation-link--next {
  position: fixed;
  right: 10px;;
  top: 50%;
}
.navigation-link--prev {
  position: fixed;
  left: 10px;
  top: 50%;
}
.navigation-link--up {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 45px;
}
.navigation-link--down {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
}
.right-to-left .navigation-link--next {
  left: 0;
  right: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.right-to-left .navigation-link--prev {
  right: 0;
  left: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}











































/*TODO active page same as menu bar color */
/*TODO make height of content item match the height of the page */
.right-to-left .contents-panel {
  left: 0;
  right: auto;
}
.contents-fade-enter-active, .contents-fade-leave-active {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.contents-fade-enter, .contents-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.contents-panel {
  width: 300px;
  height: 100vh;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 48px;
  background-color: #2D2C2D;
  -webkit-box-shadow: -5px 15px 10px 10px rgba(0,0,0,0.3);
          box-shadow: -5px 15px 10px 10px rgba(0,0,0,0.3);
  overflow-y: scroll;
  padding-bottom: 100px;
}
.contents-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}


.contents-item {
    min-height: 112px;
    clear: both;
    color: white;
    text-align: center;
    user-drag: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 2px solid white;
}
.contents-item__section {
    min-height: 108px;
}
.contents-item__subsection{
      width: 100%;
      clear: both;
      display: inline-block;
}
.contents-item__text {
    width: 50%;
    height: 108px;
    float: left;
    position: relative;
}
.contents-item__text--index {
    font-size: 2em;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%;
    margin: 0 auto;
    pointer-events: none;
}
.contents-item__text--title {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    padding: 0 5px;
    font-size: 0.85em;
    pointer-events: none;
}
.contents-item__thumb {
    width: 50%;
    height: 107px;
    float: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.contents-section__thumb {
    width: 50%;
    height: 107px;
    float: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.expanded {
    height: auto;
}
.collapsed {
    overflow: hidden;
    height: 0px;
}
.contents-item__subsection {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: rgba(255,255,255,0.1)
}
.contents-item__section:hover,
  .contents-item__section.active,
  .contents-item__subsection:hover,
  .contents-item__subsection.active{
    background-color: #0062a6!important;
}
.content-item__chevrons {
      position:absolute;
      left:0;
      right:0;
      bottom:5px;
}

#noResultsContainer {
  color: white; 
  text-align: center;
}
.search-result {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.list-enter-active, .list-leave-active {
  -webkit-transition: all 1s;
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.search-fade-enter-active, .search-fade-leave-active {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.search-fade-enter, .search-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.search-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
}
.search-panel {
  max-width: 1280px;
  margin: auto;
  padding-top: 20px;
  padding-bottom :20px;
}
.search-bar {
  /* float: left; */
  width: calc(100% - 40px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*justify-content: flex-start;*/
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 25px;
}
.search-input {
  height: 60px;
  width: 1000px;
  max-width: 90vw;
  border: none;
  font-size: 2em;
  font-weight: lighter;
  padding-left: 30px;
  padding-right: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 0;
  outline: none;
}
.search-button{
  background-color: #00FFB4;
  height: 60px;
  width: 60px;
  border: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 0;
  outline: none;
}
.search-button svg {
  height: 30px;
  width: 30px;
}
.search-results {
  padding: 0;
  margin: 0;
  list-style-type: none;
  clear: both;
}
.search-close {
  color: #eee;
  float: right;
  outline: none;
}
.search-close svg {
  width: 48px;
  height: 48px;
  fill: #00FFB4;
}
.search-result {
  width: 100%;
  float: left;
  height: 200px;
  color: #222;
  overflow: hidden;
  text-align: center;
  user-drag: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  margin: 20px;
}
.search-result__text {
  background-color: white;
  width: 50%;
  height: 180px;
  float: left;
  text-align: left;
  position: relative;
  padding: 1em 0.675em;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  top: 10px;
}
.search-result__body {
  max-height: 130px;
  overflow-y: auto;
}
.search-result__title {
  font-weight: bold;
}
.search-result__image {
  max-width: 50%;
  max-height: 200px;
  height: 100%;
  float: left;
  margin-right: -1px;
  border: 2px solid white;
  border-radius: 10px;
}
.right-to-left .search-input {
  direction: rtl;
  border-top-right-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.right-to-left .search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.right-to-left .search-result {
  float: right;
}
.right-to-left .search-bar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.right-to-left .search-result__text  {
  text-align: right;
  direction: rtl;
}
@media (min-width: 600px) {
.search-result {
    width: calc(50% - 40px);
    float: left;
}
}


.page-dots {
  position: absolute;
  text-align: center;
}
.page-dot {
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 6px;
  border: 1px solid #fff;
  background-color: #000;
}
.page-dot--active {
  background-color: #fff;
}


#video-container{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,1);
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
    z-index: 2;
}
#video-close {
    color: #ffffff;
    font-size: 48px;
    z-index: 10000;
    position: absolute;
    top: 10px;
    left: 100%;
    margin-left: -58px;
    cursor: pointer;
}
#video-close-mobile {
    color: #ffffff;
    font-size: 48px;
    z-index: 10000;
    position: absolute;
    top: 5px;
    left: 100%;
    margin-left: -28px;
    cursor: pointer;
}
#video-container #video {
    visibility: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.embed-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
}
@media screen and (max-width:1199px) {
#video-close {
    top: 5px;
}
}

    

#gallery-container[data-v-405171cd] {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  z-index: 2;
}
#gallery-next[data-v-405171cd] {
  color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 40px;
  line-height: 100vh;
  cursor: pointer;
}
#gallery-previous[data-v-405171cd] {
  color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 40px;
  line-height: 100vh;
  cursor: pointer;
}
#gallery-close[data-v-405171cd] {
  color: #ffffff;
  font-size: 48px;
  z-index: 10000;
  position: absolute;
  top: 10px;
  left: 100%;
  margin-left: -58px;
  cursor: pointer;
}
.gallery-dot[data-v-405171cd] {
  display: inline-block;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-left: 6px;
  margin-right: 6px;
  border: 1px solid #fff;
  background-color: #000;
}
.gallery-dot--active[data-v-405171cd] {
  background-color: #fff;
}
.gallery-dots[data-v-405171cd] {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.gallery-captions[data-v-405171cd] {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color:#fff;
  font-family: Arial, Helvetica, sans-serif;
}
img[data-v-405171cd] {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  position: absolute;
  left: 50%;
  top: 47%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);
}
img.active[data-v-405171cd] {
  opacity: 1;
}
img.inactive[data-v-405171cd] {
  opacity: 0;
}
p.active[data-v-405171cd] {
}
p.inactive[data-v-405171cd] {
  position: absolute;
  display: none;
}
#gallery-next-mobile[data-v-405171cd] {
  color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0px;
  line-height: 100vh;
  cursor: pointer;
}
#gallery-previous-mobile[data-v-405171cd] {
  color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 0px;
  line-height: 100vh;
  cursor: pointer;
}
#gallery-close-mobile[data-v-405171cd] {
  top: 5px;
  margin-left: -38px;
  color: #ffffff;
  font-size: 48px;
  z-index: 10000;
  position: absolute; 
  left: 100%;
  cursor: pointer;
}
@media screen and (max-width:1199px) {
#gallery-next[data-v-405171cd] {
    right: 0px;
}
#gallery-previous[data-v-405171cd] {
    left:0px;
}
#gallery-close[data-v-405171cd] {
    top: 5px;
}
}

#guide-page h4 {
    color:#000;
    text-align: center;
    font-size:2em;
    margin-bottom:0.3em;
    margin-top:0.8em;
}
#guide-page p {
    color:#000;
    font-size:1em;
    margin-top:0.8em;
}
#guide-container {
    position: absolute;
    top:48px;
    left:0px;
    width:100%;
    height: calc(100% - 48px);
    background-color:#2D2C2D;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#guide-page {
    width:70%;
    min-height:80%;
    margin:auto;
    background-color:#fff;
    padding:20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size:0.95em;
}
.guide-icons>svg, .galleries {
    height:3em;
    width:2.6em;
    color: #2D2C2D
}
.guide-navigation-prev>svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.guide-navigation-up>svg {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
.guide-navigation-down>svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
#guide-page ul {
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-left:0;
    max-width: 1060px;
}
#guide-page li {
    padding: 10px 10px;
    width:30%;
    margin: 0 1.5%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.guide-galleries {
    position:absolute;
    right:10px;
    bottom:0;
    height:50%;
    width:50%;
    padding:10px;
    text-align: center;
}

/* MEDIA */
@media only screen and (min-width:1025px) and (max-width:1470px) {
}
@media only screen and (max-width: 1024px){
#guide-page{
        font-size:0.8em
}
#guide-page li {
        padding: 10px 0px;
}
}
@media only screen and (max-width:767px) {
#guide-page{
        font-size:0.7em;
        width:90%;
}
#guide-page h4{
        font-size:2em;
}
#guide-page li{
        padding: 5px 0;
}
}
@media only screen and (max-width:600px) {
#guide-page .guide-title{
        display: none;
}
#guide-page{
        padding-top:0;
        padding-bottom:0;
}
}
@media only screen and (max-height:600px) {
#guide-page .guide-title{
        display: none;
}
#guide-page{
        padding-top:0;
        padding-bottom:0;
}
#guide-page p{
        margin-top:0.3em;
}
}
@media only screen and (max-width:400px) {
#guide-page{
        font-size:0.6em;
}
#guide-page li{
        width:50%;
        margin:0;
}
}


#audio-container{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0,0,0,1); */
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
    z-index: 2;
}
audio{
    position:absolute;
    top:0px;
    left:0px;
}
#audio-close {
    color: #ffffff;
    font-size: 48px;
    z-index: 10000;
    position: absolute;
    top: 10px;
    left: 100%;
    margin-left: -58px;
    cursor: pointer;
}
#audio-close-mobile {
    color: #ffffff;
    font-size: 48px;
    z-index: 10000;
    position: absolute;
    top: 5px;
    left: 100%;
    margin-left: -28px;
    cursor: pointer;
}
#audio-container #audio {
    visibility: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.embed-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
}
@media screen and (max-width:1199px) {
#audio-close {
    top: 5px;
}
}

    
